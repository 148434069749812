import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Caption, Heading, Body } from "../components/typography"
import { LinkButton } from "./button"
import { Grid } from "@theme-ui/components"

const FeaturedStory = ({ story, locale }) => {
  // console.log(story)
  return (
    <Intro>
      <Grid columns={[1, 1, 2]} gap={[0, 48]} sx={{ gridRow: [1, 1, null] }}>
        <div>
          <Caption color={DesignTokens.colors.primary[500]}>
            {story.label}
          </Caption>
          <Heading>{story.title}</Heading>
          <Body>
            <strong>{story.companyName}</strong>, {story.country}
          </Body>
          {locale === "it" ? (
            <LinkButton to={"/stories/" + (story.slug || "")}>
              Scopri di più
            </LinkButton>
          ) : (
            <LinkButton to={"/" + locale + "/stories/" + (story.slug || "")}>
              Discover more
            </LinkButton>
          )}
        </div>
        <div>
          {story.heroImage && (
            <img src={story.heroImage.url} alt={story.heroImage.alt} />
          )}
        </div>
      </Grid>
    </Intro>
  )
}

const Intro = styled.div`
  margin-bottom: 6rem;
  a {
    margin-top: ${DesignTokens.spacing[6]};
  }
  div {
    & > * {
      margin-bottom: ${DesignTokens.spacing[2]};
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  img {
    width: 100%;
    border-radius: ${DesignTokens.borderRadius};
  }
  @media screen and (max-width: 767px) {
    div {
      width: 100%;
      &:first-child {
        margin-top: 0;
      }
    }
    img {
      margin: 3rem 0;
    }
  }
`

export default FeaturedStory
