import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import StoriesList from "../components/storiesList"
import FeaturedStory from "../components/featuredStory"
import { Container } from "../components/utils"
import { LanguageContext, languages } from "../context/langContext"
import { getStoriesPath } from "../utils/path"

const StoriesListPage = ({ data: { page, site }, pageContext }) => {
  const locale = pageContext.locale
  const featured = page.nodes.slice(0, 1)
  const others = page.nodes.slice(1)
  const providerLanguage = languages[locale]
  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getStoriesPath(locale),
    }
  })

  return (
    <Layout locale={locale} i18nPaths={i18nPaths}>
      <SEO title={pageContext.title} lang={locale} />
      <Header locale={locale} theme="light" title="Stories" />
      <Container>
        <LanguageContext.Provider value={providerLanguage}>
          <FeaturedStory story={featured[0]} locale={locale} />
          <StoriesList stories={others} locale={locale} />
        </LanguageContext.Provider>
      </Container>
    </Layout>
  )
}

export default StoriesListPage

export const query = graphql`
  query StoriesListQuery($skip: Int!, $limit: Int!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    page: allDatoCmsStory(
      filter: { locale: { eq: $locale }, title: { nin: "null" } }
      limit: $limit
      skip: $skip
      sort: { fields: position }
    ) {
      nodes {
        id
        title
        country
        companyName
        slug
        meta {
          publishedAt(locale: "it")
        }
        heroImage {
          sizes(imgixParams: { fit: "crop", w: "350", h: "260" }) {
            base64
            width
            height
            src
            srcSet
            sizes
          }
          alt
          url
        }
      }
    }
  }
`
